@font-face {
	font-family: 'bare';
	src:    url('fonts/bare.eot?5me2yy');
	src:    url('fonts/bare.eot?5me2yy#iefix') format('embedded-opentype'),
	url('fonts/bare.ttf?5me2yy') format('truetype'),
	url('fonts/bare.woff?5me2yy') format('woff'),
	url('fonts/bare.svg?5me2yy#bare') format('svg');
	font-weight: normal;
	font-style: normal;
}
// base font size - adjust it to <p> size
$base-font-size: 16px;
$base-font-family: 'Open Sans', sans-serif;
$icon: bare;

/* Colors */
$base: #3c3c3c;
$link: #8d6945;
$link_hover: darken(#8d6945,10%);
$bgbox: #FCFCFC;
$main: #8c6a45;
$dark: #4b4b4b;
$light: #fff;
$border: #efefef;
$bg: #fff;

$green: #3B8130;
$red: #b21f28;

// Styles

$br: 12px;
$box-shadow: 0 0 10px -1px #333;

// Icons

$icon-home: "\e900";
$icon-pencil: "\e90e";
$icon-image: "\e90f";
$icon-phone: "\e901";
$icon-envelop: "\e902";
$icon-location: "\e922";
$icon-calendar: "\e928";
$icon-printer: "\e904";
$icon-floppy-disk: "\e929";
$icon-user: "\e92a";
$icon-search: "\e92c";
$icon-cogs: "\e903";
$icon-menu: "\e906";
$icon-earth: "\e931";
$icon-checkmark: "\e93b";
$icon-circle-right: "\e93d";
$icon-google-plus2: "\e908";
$icon-facebook2: "\e909";
$icon-twitter: "\e905";
$icon-xing: "\e90b";

// Media Queries
$small-screen: 480px !default;
$medium-screen: 768px !default;
$large-screen: 1024px !default;
$xlarge-screen: 1200px !default;
$hd-screen: 1400px !default;
$screen: "only screen" !default;

$small: "only screen and (min-width: #{$small-screen})" !default;
$small-max: "only screen and (max-width: #{$small-screen+1})";
$medium: "only screen and (min-width:#{$medium-screen})" !default;
$medium-max: "only screen and (max-width: #{$medium-screen+1})";
$large: "only screen and (min-width:#{$large-screen})" !default;
$large-max: "only screen and (max-width: #{$large-screen+1})";
$xlarge: "only screen and (min-width:#{$xlarge-screen})" !default;
$xlarge-max: "only screen and (max-width: #{$xlarge-screen+1})";
$hd: "only screen and (min-width:#{$hd-screen})" !default;
$landscape: "only screen and (orientation: landscape)" !default;
$portrait: "only screen and (orientation: portrait)" !default;


