// Zeug fürs Grid
@mixin clearfix() {
	*zoom:1;
	&:before, &:after { content: " "; display: table; }
	&:after { clear: both; }
}

@mixin ghostVerticalAlign(){
	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%; width: .1px;
	}
}
// Button
@mixin button($bgcolor: $dark) {
	background: $bgcolor;
	color: $light;
	padding: 6px 12px;
	display: inline-block;
	border-radius: 5px;
	transition: .3s all ease 0s;
	&:hover {
		background: $link;
		color: $light;
	}
}
// Hintergrundblock
@mixin bgblock(){
	margin-bottom: 24px;

	background: $light;
	display: block;
	float: left;
	width: 100%;
}
// Clear all
@mixin clear() {
	float: left;
	width: 100%;
	clear: both;
	display: block;
}
// Icon to before
@mixin icon($inputicon, $dir: left) {
	@if $dir == "left" {
		&:before {
			content: $inputicon;
			font-family: $icon;
			padding-right: 6px;
		}
	}
	@if $dir == "none" {
		&:before {
			content: $inputicon;
			font-family: $icon;
		}
	}
	@if $dir == "right" {
		&:after {
			content: $inputicon;
			font-family: $icon;
			padding-left: 6px;
		}
	}
}

//Gradients

@mixin bgheader(){
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c1d3eb+0,ffffff+19 */
	background: #c1d3eb; /* Old browsers */
	background: -moz-linear-gradient(left,  #c1d3eb 0%, #c1d3eb 35%, #ffffff 55%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  #c1d3eb 0%,#c1d3eb 35% ,#ffffff 55%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #c1d3eb 0%,#c1d3eb 35%, #ffffff 55%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c1d3eb', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
@mixin bgheadersub(){
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c1d3eb+0,ffffff+19 */
	background: #c1d3eb; /* Old browsers */
	background: -moz-linear-gradient(left,  rgb(236, 233, 228) 0%, rgb(236, 233, 228) 35%, #ffffff 55%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  rgb(236, 233, 228) 0%,rgb(236, 233, 228) 35% ,#ffffff 55%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  rgb(236, 233, 228) 0%,rgb(236, 233, 228) 35%, #ffffff 55%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}