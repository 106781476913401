* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: $link;
	transition: .5s all ease-in-out 0s;
	&:hover {
		color: $link_hover;
	}
}

h1, h1 * {
	font-size: 26px;
	line-height: 30px;
	@media#{$large}{
		font-size: 32px;
		line-height: 36px;
	}
	margin-bottom: 24px;
	margin-top: 0;
	font-weight: normal;
	color: $main;
}

h2, h2 * {
	font-size: 24px;
	line-height: 30px;
	@media#{$large}{
		font-size: 30px;
		line-height: 36px;
	}
	margin-bottom: 24px;
	margin-top: 0;
	font-weight: bold;
	color: $base;
}

h3, h3 * {
	font-size: 24px;
	line-height: 30px;
	@media#{$large}{
		font-size: 30px;
		line-height: 36px;
	}
	margin-bottom: 24px;
	margin-top: 0;
	font-weight: normal;
}

h4, h4 * {
	font-weight: bold;
	margin-bottom: 24px;
	margin-top: 0;
}

p {
	color: $base;
	line-height: 24px;
	margin-bottom: 24px;
}

ul, ol {
	margin-left: 24px;
	li {
		line-height: 24px;
	}
}

.item-page {
	ul, ol {
		margin-bottom: 24px;
	}
}

img {
	max-width: 100%;
	height: auto;
}

hr {
	height: 1px;
	width: 100%;
	background-color: $dark;
	clear: both;
	margin-bottom: 24px;
}

.item-image {
	display: block;
	margin: 0 auto 6px;
	text-align: center;
	.img_caption {
		font-size: 14px;
		max-width: 100%;
	}
	&.pull-right {
		max-width: 100%;
		@media#{$medium}{
			float: right;
			max-width: 50%;
			margin-left: 12px;
			margin-bottom: 6px;
		}
		.img_caption {
			text-align: right;
			padding-top: 12px;
		}
	}
	&.pull-left {
		max-width: 100%;
		@media#{$medium}{
			float: left;
			max-width: 50%;
			margin-right: 12px;
			margin-bottom: 6px;
		}
		.img_caption {
			text-align: left;
			padding-top: 12px;
		}
	}
}

/*Artikelinfos*/
dl.article-info {
	font-size: 12px;
	margin-bottom: 12px;
	span {
		font-size: 12px;
	}
	time {
		font-size: 12px;
	}
	dd.createdby {
		font-size: 12px;
		span {
			font-size: 12px;
		}
	}
}

/*Pagination*/

.pagination {
	clear: both;
	float: left;
	width: 100%;
	margin-bottom: 24px;
	margin-top: 24px;
	p.counter {
		display: none;
		margin-bottom: 0;
		@media #{$large}{
			float: right;
			display: block;
		}
	}
	ul {
		margin-left: 0;
		width: 100%;
		li {
			list-style: none;
			display: inline;
			margin-right: 10px;
			a {
				padding: 5px 10px;
				color: $link;
				background: $light;
				border: 1px solid $dark;
				&:hover {
					color: $link-hover;
				}
			}
			span {
				padding: 5px 10px;
			}
			&.pagination-end, &.pagination-start {
				display: none;
			}
			&.pagination-next {
				@media #{$large-max}{
					float: right;
					margin-right: 0;
				}
			}
			@media #{$large-max}{
				display: none;
				&.pagination-prev, &.pagination-next {
					display: inline-block;
				}
			}
		}
	}
}

ul.pagenav {
	width: 100%;
	float: left;
	clear: both;
	margin-left: 0;
	list-style: none;
	li {
		display: inline-block;
		a {
			background: $main;
			color: $light;
			padding: 5px;
			font-size: 12px;
			display: block;
			margin-bottom: 12px;
			&:hover {
				background: darken($main, 10%);
			}
		}
	}
	li.pagenav-prev {
		float: left;
		a:before {
			content: '\ea44';
			padding-right: 5px;
			font-family: icomoon;
		}
	}
	li.pagenav-next {
		float: left;
		clear: both;
		@media#{$medium}{
			float: right;
			clear: none;
		}
		a:after {
			content: '\ea42';
			padding-left: 5px;
			font-family: icomoon;
		}
	}
}

input#button6, input.button, .moduletable .button, .btn {
	@include button();
	margin-bottom: 12px;
}

/* Fieldset reset */
fieldset {
	border: 0;
	margin: 0;
	margin-bottom: 24px;
	padding: 0;
	.btn-group {
		float: left;
		label.element-invisible {
			display: none;
		}
		#filter-search {
			min-width: 210px;
			padding: 4px;
			background: $dark;
			color: $light;
			border-radius: 3px;
			font-size: 14px;
		}
		&.pull-right {
			float: right;
			select {
				border: 1px solid $border;
			}
		}
	}
}

.tooltip.fade {
	display: none !important;
}

/* Table */

table {
	width: 100%;
	max-width: 100%;
	color: $dark;
	margin-bottom: 24px;
	table-layout: fixed;
	th {
		background-color: $dark;
		padding: 5px 10px;
		color: $light;
		text-align: left;
		font-size: 12px;
		transition: .5s all ease 0s;
		* {
			color: $light;
			margin-bottom: 0;
		}
		@media #{$medium}{
			font-size: 16px;
		}
	}
	tbody {

		tr {
			transition: .5s background-color ease 0s;
			td {
				padding: 5px 5px;
				vertical-align: top;

				@media#{$medium-max}{
					width: 50% !important;
					display: block;
					float: left;
					word-break: break-word;
				}
				@media#{$small-max}{
					width: 100% !important;
				}
				img {
					margin: 0 auto 24px;
					display: block;
					float: none !important;
				}
				@media#{$small}{
				}
				@media #{$medium}{
					padding: 5px 10px;
				}
				@media#{$large}{
					float: none;
					display: table-cell;
					box-sizing: border-box;
					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						padding-right: 0;
					}
				}

				a {
					font-weight: bold;
				}

			}

		}
	}
	&.zebra {
		border: 1px solid $border;
		tr {
			&:nth-child(odd) {
				background-color: $bg;
				&:hover {
					background-color: darken($bg, 10%);
				}
			}
			&:nth-child(even) {
				background-color: $light;
				&:hover {
					background-color: darken($bg, 10%);
				}
			}
			td {
				@media#{$medium}{
					border-right: 1px solid $border;
				}
				&:last-child {
					border-right: 0;
				}
				&:first-child {
					padding-left: 5px;
				}
			}
		}
	}
	&.info {
		width: auto;
		@media#{$small-max}{
			width: 100% !important;
		}
		th, th * {
			font-size: 13px;
		}
		tr {
			td {
				font-size: 13px;
				* {
					font-size: 13px;
				}
				&:first-child, &:last-child {
					padding: 5px 5px;
					@media #{$medium}{
						padding: 5px 10px;
					}
				}
			}
		}
	}
	&.sticky {
		min-width: 724px;
		tr {
			td {
				@media#{$medium-max}{
					width: auto !important;
					display: table-cell;
					float: none;
					border-right: 1px solid $border;
					word-break: normal;
				}
				@media#{$small-max}{
					width: auto !important;
				}
			}
		}
	}
	&.stacked {
		width: 100% !important;
		tr {

			border-bottom: 1px solid $link_hover;
			margin-bottom: 1px;
			padding-bottom: 1px;
			&:first-child {
				td {
					font-weight: bold;
					* {
						margin-bottom: 0;
					}
				}
			}
			> td {
				padding: 5px !important;
				text-align: center;
				* {
					margin-bottom: 0;
					text-align: center;
				}
			}
			td:first-child {
				font-weight: bold;
				padding: 5px 10px;
				background: darken($bg, 10%);
			}
			td:nth-child(2) {
				background: lighten(#ddd, 10%)
			}
			td:nth-child(3) {
				background: lighten(#ddd, 5%)
			}
			td:nth-child(4) {
				background: #ddd;
			}
		}
	}
	&.category {

	}
	&.dfContactTable {
		tr th {
			width: 54px;
			padding: 5px 10px;
			text-align: center;
		}
	}
}

.sketch_grid {
	margin-bottom: 24px;
	&.tleft {
		float: left;
	}
}

// Inputs
input {
	&[type='text'], &[type='password'], &[type='email'] {
		border: 1px solid $border;
		padding: 2px;
	}
}

textarea {
	border: 1px solid $border;
	padding: 2px;
}

select {
	border: 1px solid $border;
}

.sketch_calendar {
	.btn {
		padding: 1px 11px;
		border-radius: 0 5px 5px 0;
		span.icon-calendar {
			@include icon($icon-calendar);
			&:before {
				padding-right: 0;
			}
		}
	}
}

// In Item-page
// Article Summary
.article-index {
	background: $bg;
	padding: 12px;
	margin-bottom: 24px;
	&.pull-right {
		float: right;
	}
	&.pull-left {
		float: left;
	}
}

.pagenavcounter {
	float: right;
	background: $dark;
	color: $light;
	border-radius: 8px;
	padding: 4px 8px;
	clear: right;
	margin-bottom: 24px;
}

.pager {
	ul {
		width: 100%;
		float: left;
		clear: both;
		margin-top: 24px;
		li {
			padding-left: 0;
			&:first-child {
				float: left;
			}
			&:last-child {
				float: right;
			}
		}
	}
}

.alert {
	background-color: rgba($light, .92);
	padding: 12px;
	overflow: hidden;
	float: left;
	width: 100%;
	margin-bottom: 24px;
	border: 2px solid $border;
	.close {
		border: 1px solid $border;
		border-radius: 100%;
		width: 19px;
		line-height: 18px;
		height: 19px;
		display: block;
		text-align: center;
		color: $border;
		float: right;
		cursor: pointer;
	}
	p {
		margin-bottom: 6px;
	}
	&.alert-message {
		border: 2px solid $main;
		.close {
			border: 1px solid $main;
			color: $main;
		}
	}
	&.alert-warning, &.alert-error {
		border: 2px solid $red;
		.close {
			border: 1px solid $red;
			color: $red;
		}
	}
	&.alert-success, &.alert-notice {
		border: 2px solid $green;
		.close {
			border: 1px solid $green;
			color: $green;
		}
	}
}

// Base Styling Inputs

input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="phone"], select, textarea {
	border: 1px solid $border;
}

// Icons for Base Elements
a[href^="mailto:"] {
	&:before {
		content: $icon-envelop;
		font-family: $icon;
		padding-right: 6px;
	}
}

a[href^="tel:"] {
	&:before {
		content: $icon-phone;
		font-family: $icon;
		padding-right: 6px;
	}
}
span.fax{
	&:before{
		@include icon($icon-printer);
	}
}

*[data-title] {
	cursor: pointer;
	position: relative;
	&:hover {
		&:after {
			content: attr(data-title);
			padding: 4px 8px;
			color: #333;
			position: absolute;
			left: 12px;
			top: 82%;
			font-size: 14px;
			max-width: 500px;
			text-transform: initial;
			z-index: 20;
			box-shadow: 0 0 4px #222;
			background: $bg;
		}
	}
}

// Frontend Joomla-native Popups
.popover {
	top: 0;
	left: 0;
	z-index: 1010;
	display: none;
	max-width: 276px;
	padding: 1px;
	text-align: left;
	background-color: $light;
	border: 1px solid $border;
	border-radius: 6px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
	white-space: normal;
	position: absolute;
	&.fade {
		opacity: 0;
		-webkit-transition: opacity .15s linear;
		transition: opacity .15s linear;
		&.in {
			opacity: 1;
		}
	}
	&.top {
		margin-top: -10px;
		> .arrow {
			left: 50%;
			margin-left: -11px;
			border-bottom-width: 0;
			border-top-color: $border;
			bottom: -11px;
			&:after {
				bottom: 1px;
				margin-left: -10px;
				border-bottom-width: 0;
				border-top-color: $light;
			}
		}
	}
	&.right {
		margin-left: 10px;
		> .arrow {
			top: 50%;
			left: -11px;
			margin-top: -11px;
			border-left-width: 0;
			border-right-color: $border;
			&:after {
				left: 1px;
				bottom: -10px;
				border-left-width: 0;
				border-right-color: $light;
			}
		}
	}
	&.bottom {
		margin-top: 10px;
		> .arrow {
			left: 50%;
			margin-left: -11px;
			border-top-width: 0;
			border-bottom-color: $border;
			top: -11px;
			&:after {
				top: 1px;
				margin-left: -10px;
				border-top-width: 0;
				border-bottom-color: $light;
			}
		}
	}
	&.left {
		margin-left: -10px;
		> .arrow {
			top: 50%;
			right: -11px;
			margin-top: -11px;
			border-right-width: 0;
			border-left-color: $border;
			&:after {
				right: 1px;
				border-right-width: 0;
				border-left-color: $light;
				bottom: -10px;
			}
		}
	}
	> .arrow {
		border-width: 11px;
		&:after {
			border-width: 10px;
			content: "";
		}
	}
	> .arrow, > .arrow:after {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
	.popover-title {
		margin: 0;
		padding: 8px 14px;
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
		background-color: $main;
		color: $light;
		border-bottom: 1px solid $border;
		border-radius: 5px 5px 0 0;
	}
	.popover-content {
		padding: 9px 14px;
	}
}

.popover.bottom > .arrow:after, .popover.left > .arrow:after, .popover.right > .arrow:after, .popover.top > .arrow:after {
	content: " ";
}

