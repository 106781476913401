.sketchwrap_slider {
	.sketchslider_pro-wrap {
		.sketchslider_pro {
			margin-bottom: 0;
			ul.slides {
				li {
					a {
						.boxwrap {
							position: relative;
							max-width: 100%;
							@media#{$large}{
								position: absolute;
								max-width: 725px;
							}
							bottom: 100%;
							right: 0;
							top: 7vh;

							span.slogan {
								font-weight: normal;
								font-size: 34px;
								line-height: 40px;
								margin-bottom: 48px;
								@media#{$large}{
									font-size: 48px;
									line-height: 56px;
									margin-bottom: 24px;
								}
								text-shadow: 0 0 4px #000;
								color: $light;
								display: block;
							}
							.box_custom {
								background: rgba($light, .9);
								position: relative;
								margin-right: 80px;
								padding: 40px;
								height: 325px;

								&:after {
									content: '';
									width: 80px;
									height: 325px;
									top: 0;
									position: absolute;
									right: -80px;
									background-image: url("../images/box_side.png");
								}
								h2 {
									font-weight: normal;

								}
								.slide_description {
								}
								.slimScrollDiv {
									margin-bottom: 24px;
								}
							}

						}
						img{
							position: absolute;
							z-index: -1;
							top: 0;
							@media#{$large}{
								position: relative;
							}
						}
					}
				}
			}
		}
	}
}