* {
	font-size: $base-font-size;
	font-family: $base-font-family;
}

body {
	background-color: $bg;
}

.allwrap {
	overflow: hidden;
}

header {
	width: 100%;
	min-height: 80px;
	border-bottom: 1px solid $border;
	@include bgheadersub();
	&.has_slider {
		.moduletable.logo {
			background: #c8d6eb;
		}
		@include bgheader();
	}
	.moduletable.logo {
		margin-top: 18px;
		background: rgb(236, 233, 228);
		float: left;
		padding-left: 5px;
		padding-right: 5px;
		padding-bottom: 5px;
		margin-bottom: -38px;
		display: block;
		border-radius: 5px;
		z-index: 9999;
		position: relative;
		img {
			display: block;
		}
	}
}

main {
	padding-top: 24px;
	padding-bottom: 24px;
}

footer {
	width: 100%;
	float: left;
	clear: both;
	background: $main;
	padding-top: 48px;
	background: $dark;
	p, a, span, strong, li, td, div {
		color: $light;
		font-size: 14px;
		letter-spacing: -.4px;
	}
	.content_bottom_1_2 {
		border-bottom: 1px solid $border;
		padding-bottom: 48px;
		@media#{$large}{
			border-right: 1px solid $border;
			border-bottom: 0;
			padding-bottom: 0;
		}
	}
	a:hover {
		color: darken($light, 10%);
	}
	.sketchwrap_bottom {
		background: $base;
		float: left;
		width: 100%;
		margin-top: 48px;
		padding-top: 6px;
		padding-bottom: 6px;
		span.copyright {
			color: $border;
			font-size: 14px;
		}
	}
	span {
		&.big {
			text-transform: uppercase;
			font-weight: bold;
			font-size: 34px;
			line-height: 24px;
			letter-spacing: -1px;
			display: block;
		}
		&.small {
			text-transform: uppercase;
			font-weight: lighter;
			display: block;
			font-size: 15px;
		}
	}
	h4 {
		margin-bottom: 0;
		text-transform: uppercase;
		font-weight: normal;
		margin-top: 46px;
	}
	.content_bottom_2_2 {
		div {
			font-size: 14px;
			letter-spacing: -.4px;
		}
	}
}

.match {
	transition: none !important;
}