div.top_right {
	.moduletable {
		ul.nav.menu {
			margin-left: 0;
			margin-bottom: 0;
			margin-top: 36px;
			text-align: right;
			li {
				display: inline-block;
				list-style: none;
				position: relative;
				&:last-child {
					border-right: 0;
					> a {
						padding-right: 0;
					}
				}
				&:first-child {
					a {
					}
				}
				&.parent {
					a {
						&:after {
							content: '>';
							font-family: monospace;
							position: absolute;
							transform: rotate(90deg);
							left: 50%;
							bottom: 0;
							opacity: 0;
							transition: .3s all ease 0s;
						}
					}
				}
				> a {
					color: $base;
					padding: 0 12px;
					transition: .6s all ease 0s;
					text-decoration: none;
					display: block;
					font-size: 16px;
					font-weight: normal;
					padding-bottom: 17px;
				}
				&:hover, &.active {
					> a {
						color: $link;
					}
				}
				&.parent:hover {
					> a:after {
						opacity: 1;
					}
				}
				ul.nav-child {
					position: absolute;
					top: 60px;
					left: 0;
					transition: .5s all ease 0s;
					margin-left: 0;
					width: auto;
					visibility: hidden;
					opacity: 0;
					z-index: 99;
					min-width: 152px;
					padding-top: 12px;
					li {
						display: block;
						float: none;
						margin-right: 0;
						&:first-child a {
							border-left: 0;
						}
						a {
							padding: 0px 10px;
							text-transform: none;
							letter-spacing: 0;
							font-family: $base-font-family;
							font-weight: normal;
							color: $base;
							border-right: 0;
							text-align: left;
							&:hover {
								color: $link;
							}
							&:after {
								content: '';
							}
							&:first-child {
								border-left: 0;
							}
						}
						&.active {
							a {
								color: $light;
							}
						}
						>ul.nav-child{
							left: 200%;
							top: 0;
							opacity: 0;
							padding-top: 0;
							visibility: hidden;
						}
						&:hover{
							>ul.nav-child{
								opacity: 1;
								display: block;
								visibility: visible;
								left: 100%;
							}
						}
					}
				}
				&:hover {
					ul.nav-child {
						opacity: 1;
						display: block;
						visibility: visible;
						top: 43px;
					}
				}

			}
		}
	}
}

nav.bottom {
	.moduletable {
		ul.nav {
			margin-left: 0;
			li {
				display: inline;
				list-style: none;
				margin-right: 12px;
				@media#{$xlarge}{
					margin-right: 24px;
				}
				&:last-child {
					margin-right: 0;
				}
				a {
					font-size: 14px;
					color: $light;
				}
			}
		}
	}
}

.slidenav1, .slidenav2, .slidenav3, .slidenav4 {
	display: none;
	@media #{$large}{
		display: block;
	}
}

/* Icon 4 */

#main-nav {
	width: 45px;
	height: 35px;
	position: relative;
	margin: 12px auto;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	z-index: 9999;
	@media #{$large}{
		display: none;
	}
	span, &:before, &:after {
		display: block;
		position: absolute;
		height: 5px;
		width: 100%;
		background: $base;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
	}
	&:before {
		top: 0px;
		content: '';
		transform-origin: left center;
	}
	&:after {
		content: '';
		top: 12px;
		transform-origin: left center;
	}
	span {
		top: 24px;
		transform-origin: left center;
	}
	&.open:before {
		transform: rotate(45deg);
		top: -3px;
		left: 8px;
	}
	&.open:after {
		width: 0;
		opacity: 0;
	}
	&.open span {
		transform: rotate(-45deg);
		top: 29px;
		left: 8px;
	}
	&.open {
		span, &:before, &:after {
			background: $link_hover;
		}
	}
}