.moduletable.search{
	float: right;
	text-align: right;
	padding: 0;
	border: 1px solid $border;
	margin-top: 24px;
	margin-bottom: 24px;
	@media #{$large}{
		text-align: left;
	}
	.search{
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 0;
		padding-left: 0;
		button.button.btn.btn-primary {
			background: $link;
			margin-bottom: 0;
			float: left;
			padding: 3px 8px;
			&:before{
				content: $icon-search;
				font-family: $icon;
			}
			&:hover{
				background: $link_hover;
			}
		}
	}
}
input#search-searchword,input.search-query {
	min-width: 140px;
	padding: 5px;
	background: $light;
	color: $dark;
	font-size: 14px;
}

.search{
	.only, .phrases{
		label{
			font-size: 12px;
			input{
				position: relative;
				top: 2px;
			}
		}
		a.chzn-single span{
			font-size: 12px;
		}
		ul.chzn-results{
			li{
				font-size: 12px;
			}
		}
	}
	.form-limit{
		text-align: right;
		.chzn-container {
			min-width: 60px;
		}
	}
	dl.search-results{
		dt.result-title{
			font-weight: bold;
			margin-top: 12px;
			&:first-child{
				margin-top: 0;
			}
		}
		dd.result-category{
			span.small{
				font-size: 12px;
			}
		}
	}
	.btn-group.pull-left{
		float: left;
	}
	.searchintro {
		float: left;
		width: 100%;
		clear: both;
	}
	.clearfix{
		float: left;
		width: 100%;
		clear: both;
	}
	#searchForm{
		button[name="Search"]{
			float: left;
			padding: 4px 12px;
			border-radius: 0 3px 3px 0;
			&:before{
				content: $icon-search;
				font-family: $icon;
			}
		}
	}
}